import React, {useEffect} from "react"
import Header from "../../components/header"
import Trade from "../../components/trade"
import { FaTrophy } from 'react-icons/fa'
import teamsData from "../../../static/data/teams.json"
import tradesData from "../../../static/data/trades.json"
import { Chart } from 'chart.js'
import {Helmet} from "react-helmet"

function Page({teams}) {
  const season = "2020";
  const teamNames = teamsData.map((team) => team.seasons[season].name);
  const teamColors = teamsData.map((team) => team.color);
  const trades = tradesData[0][season].trades;

  useEffect(() => {
    const teams = teamsData;

    new Chart(document.getElementById("chartPF"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].pointsFor),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsPossible),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPercPP"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => (
            (team.seasons[season].pointsFor / team.seasons[season].pointsPossible) * 100).toFixed(2)
          ),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartPA"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].pointsAgainst),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartTrades"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].tradeCount),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              precision: 0
            }
          }]
        }
      }
    });

    new Chart(document.getElementById("chartAvgScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].avgScore),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    // doesn't pull from array
    new Chart(document.getElementById("chartHighestScore"), {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data: [
            '191.04',
            '169.22',
            '166.50',
            '165.76',
            '161.94',
            '160.60',
            '153.84',
            '153.72',
            '153.55',
            '151.42'
          ],
          backgroundColor: [
            teamColors[3],
            teamColors[0],
            teamColors[0],
            teamColors[7],
            teamColors[0],
            teamColors[7],
            teamColors[5],
            teamColors[5],
            teamColors[1],
            teamColors[0]
          ],
        }],
        labels: [
          teamNames[3] + ' - Week 16',
          teamNames[0] + ' - Week 2',
          teamNames[0] + ' - Week 3',
          teamNames[7] + ' - Week 1',
          teamNames[0] + ' - Week 8',
          teamNames[7] + ' - Week 11',
          teamNames[5] + ' - Week 9',
          teamNames[5] + ' - Week 5',
          teamNames[1] + ' - Week 13',
          teamNames[0] + ' - Week 7'
        ]
      },
      options: {
        legend: {
          display: false
        }
      }
    });

    new Chart(document.getElementById("chartAboveAvgPerc"), {
      type: 'polarArea',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].aboveAvgPerc),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartWeeklyPlaces"), {
      type: 'line',
      data: {
        labels: [
          'Week 1',
          'Week 2',
          'Week 3',
          'Week 4',
          'Week 5',
          'Week 6',
          'Week 7',
          'Week 8',
          'Week 9',
          'Week 10',
          'Week 11',
          'Week 12',
          'Week 13',
          'Week 14',
          'Week 15',
          'Week 16'
        ],
        datasets: 
          teams.map((team,idx) => (
            {
              label: team.seasons[season].name,
              data: team.seasons[season].weeklyPlaces,
              fill: false,
              borderColor: team.color,
              backgroundColor: team.color,
              borderCapStyle: 'square'
            }
          ))
      },
      options: {
        legend: {
          display: true,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              reverse: true
            }
          }]
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartOriginalTeam"), {
      type: 'polarArea',
      data: {
        datasets: [{
            data: teams.map((team) => team.seasons[season].originalTeamPerc),
            backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartFaabSpent"), {
      type: 'doughnut',
      data: {
        datasets: [{
          data: teams.map((team) => team.seasons[season].faabSpent),
          backgroundColor: teamColors,
        }],

        labels: teamNames
      },
      options: {
        legend: {
          display: true,
          position: 'left'
        },
        responsive: true,
      }
    });

    new Chart(document.getElementById("chartTier1"),{
      type:"horizontalBar",
      data:{
        labels: teamNames,
        datasets:[
          {
            label: "QB",
            data: teams.map((team) => team.seasons[season].tier1_QB.length),
            backgroundColor: "#C62828"
          },
          {
            label: "RB",
            data: teams.map((team) => team.seasons[season].tier1_RB.length),
            backgroundColor: "#2E7D32"
          },
          {
            label: "WR",
            data: teams.map((team) => team.seasons[season].tier1_WR.length),
            backgroundColor: "#1565C0"
          },
          {
            label: "TE",
            data: teams.map((team) => team.seasons[season].tier1_TE.length),
            backgroundColor: "#F9A825"
          },
          {
            label: "D/ST",
            data: teams.map((team) => team.seasons[season].tier1_DST.length),
            backgroundColor: "#6A1B9A"
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    });
  }, [teams]) 

  return (
    <React.Fragment>
      <Helmet>
        <title>2020 Season Recap | Chode Dynasty</title>
      </Helmet>
      
      <Header />
      <main>
        <div className="row">
          <div className="inner">
            <h1>2020 Season Recap</h1>
            
            <div className="cols-2">
              <div>
                <h2>Final Standings</h2>
                <ol className="standings">
                  <li>
                    <span className="label">1<sup>st</sup></span> Wet Ass Poopy (8-6)
                    <div className="trophy place-1"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">2<sup>nd</sup></span> Chodemissioner (14-0)
                    <div className="trophy place-2"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">3<sup>rd</sup></span> The Gandy-Golden Boys (8-6)
                    <div className="trophy place-3"><FaTrophy /></div>
                  </li>
                  
                  <li>
                    <span className="label">4<sup>th</sup></span> GingerBeard77 (9-5)
                  </li>
                  
                  <li>
                    <span className="label">5<sup>th</sup></span> Prestige Worldwide (7-7)
                  </li>
                  
                  <li>
                    <span className="label">6<sup>th</sup></span> Back Ditch Water (7-7)
                  </li>
                  
                  <li>
                    <span className="label">7<sup>th</sup></span> Piss Jugs (6-8)
                  </li>
                  
                  <li>
                    <span className="label">8<sup>th</sup></span> joog (5-9)
                  </li>
                  
                  <li>
                    <span className="label">9<sup>th</sup></span> jhoffman (4-10)
                  </li>
                  
                  <li>
                    <span className="label">10<sup>th</sup></span> frankakatank (2-12)
                  </li>
                </ol>
              </div>

              <div>
                <h2>Champion Roster</h2>
                <table className="styled champ">
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>Player</th>
                      <th>Points</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>QB</td>
                      <td>Tom Brady (TB)</td>
                      <td>37.92</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>Miles Sanders (PHI)</td>
                      <td>16.90</td>
                    </tr>
                    <tr>
                      <td>RB</td>
                      <td>Austin Ekeler (LAC)</td>
                      <td>14.30</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>Adam Thielen (MIN)</td>
                      <td>19.70</td>
                    </tr>
                    <tr>
                      <td>WR</td>
                      <td>Diontae Johnson (PIT)</td>
                      <td>17.70</td>
                    </tr>
                    <tr>
                      <td>TE</td>
                      <td>Travis Kelce (KC)</td>
                      <td>19.30</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>Robert Tonyan (GB)</td>
                      <td>2.20</td>
                    </tr>
                    <tr>
                      <td>WRT</td>
                      <td>Derrick Henry (TEN)</td>
                      <td>9.80</td>
                    </tr>
                    <tr>
                      <td>DST</td>
                      <td>Philadelphia Eagles</td>
                      <td>-6.00</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td><strong>131.82</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>

        <div id="awards" className="row">
          <div className="inner">
            <h2>Awards</h2>
            <div className="cols-2">
              <div>
                <h3><span role="img" aria-label="rocket">🚀</span> Highest Scorer</h3>
                <h4>Chodemissioner</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="pile of poo">💩</span>Lowest Scorer</h3>
                <h4>frankakatank</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="four-leaf clover">🍀</span>Best Luck</h3>
                <div className="desc">(least points against)</div>
                <h4>Piss Jugs</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="face with head-bandage">🤕</span>Worst Luck</h3>
                <div className="desc">(most points against)</div>
                <h4>Wet Ass Poopy</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="fire">🔥</span>Best Manager</h3>
                <div className="desc">(highest percentage of possible points scored)</div>
                <h4>Chodemissioner</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="thinking face">🤔</span>Worst Manager</h3>
                <div className="desc">(lowest percentage of possible points scored)</div>
                <h4>Wet Ass Poopy</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="increasing chart">📈</span>Most Improved</h3>
                <div className="desc">(biggest increase from last year in points for)</div>
                <h4>The Gandy-Golden Boys (+173.13)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="decreasing chart">📉</span>Least Improved </h3>
                <div className="desc">(biggest decrease from last year in points for)</div>
                <h4>jhoffman (-221.84)</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="check mark">✔️</span>Most Consistent Scorer</h3>
                <h4>Back Ditch Water</h4>
              </div>
              <div>
                <h3><span role="img" aria-label="cross mark">❌</span>Least Consistent Scorer </h3>
                <h4>jhoffman</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points For</h2>
            <canvas id="chartPF"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Possible</h2>
            <canvas id="chartPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Possible Points Scored</h2>
            <canvas id="chartPercPP"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Total Points Against</h2>
            <canvas id="chartPA"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Team Average Score</h2>
            <canvas id="chartAvgScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>% of Games with Above Average Score</h2>
            <canvas id="chartAboveAvgPerc"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Highest Scores</h2>
            <canvas id="chartHighestScore"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Finishes</h2>
            <canvas id="chartWeeklyPlaces"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Percentage of Startup Draft Team</h2>
            <div className="chart-desc">(% of original team remaining)</div>
            <canvas id="chartOriginalTeam"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>FAAB Spent (out of $100)</h2>
            <canvas id="chartFaabSpent"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Top Scoring Players by Position</h2>
            
            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Quarterback</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
        
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>A. Rodgers</td>
                      <td>474.8</td>
                      <td>Prestige Worldwide</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>J. Allen</td>
                      <td>462.1</td>
                      <td>joog</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>P. Mahomes</td>
                      <td>449.1</td>
                      <td>The Gandy-Golden Boys</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>R. Wilson</td>
                      <td>434.3</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>D. Watson</td>
                      <td>428.8</td>
                      <td>Back Ditch Water</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>K. Murray</td>
                      <td>421.7</td>
                      <td>GingerBeard77</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>T. Brady</td>
                      <td>413.9</td>
                      <td>Wet Ass Poopy</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>R. Tannehill</td>
                      <td>403.9</td>
                      <td>Prestige Worldwide</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>J. Herbert</td>
                      <td>391.3</td>
                      <td>Wet Ass Poopy</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>L. Jackson</td>
                      <td>377.8</td>
                      <td>The Gandy-Golden Boys</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>K. Cousins</td>
                      <td>370.9</td>
                      <td>joog</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>B. Roethlisberger</td>
                      <td>329.7</td>
                      <td>Prestige Worldwide</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Running Back</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>A. Kamara</td>
                      <td>338.3</td>
                      <td>The Gandy-Golden Boys</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>D. Henry</td>
                      <td>320.6</td>
                      <td>Wet Ass Poopy</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>D. Cook</td>
                      <td>310.8</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>D. Montgomery</td>
                      <td>238.3</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>J. Taylor</td>
                      <td>235.3</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>A. Jones</td>
                      <td>233.4</td>
                      <td>GingerBeard77</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>J. Robinson</td>
                      <td>224.4</td>
                      <td>Wet Ass Poopy</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>J. Jacobs</td>
                      <td>212.8</td>
                      <td>joog</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>K. Hunt</td>
                      <td>199.0</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>N. Chubb</td>
                      <td>198.7</td>
                      <td>Prestige Worldwide</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>E. Elliott</td>
                      <td>193.2</td>
                      <td>Wet Ass Poopy</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>A. Gibson</td>
                      <td>183.7</td>
                      <td>The Gandy-Golden Boys</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Wide Receiver</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>D. Adams</td>
                      <td>299.9</td>
                      <td>GingerBeard77</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>T. Hill</td>
                      <td>289.9</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>S. Diggs</td>
                      <td>266.6</td>
                      <td>Prestige Worldwide</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>C. Ridley</td>
                      <td>237</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>D. Metcalf</td>
                      <td>233.3</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>D. Hopkins</td>
                      <td>231.8</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>J. Jefferson</td>
                      <td>230.7</td>
                      <td>Back Ditch Water</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>A. Thielen</td>
                      <td>218.5</td>
                      <td>Wet Ass Poopy</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>T. Lockett</td>
                      <td>215.9</td>
                      <td>Prestige Worldwide</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>M. Evans</td>
                      <td>213.6</td>
                      <td>joog</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>A. Robinson</td>
                      <td>213.4</td>
                      <td>GingerBeard77</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>A. Brown (TEN)</td>
                      <td>212</td>
                      <td>The Gandy-Golden Boys</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="top-score-pos">
                <h3>Tight End</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>T. Kelce</td>
                      <td>259.3</td>
                      <td>Wet Ass Poopy</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>D. Waller</td>
                      <td>226.1</td>
                      <td>joog</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>R. Tonyan</td>
                      <td>150.6</td>
                      <td>Wet Ass Poopy</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>L. Thomas</td>
                      <td>146.6</td>
                      <td>Back Ditch Water</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>T. Hockenson</td>
                      <td>142.3</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>M. Andrews</td>
                      <td>142.1</td>
                      <td>Prestige Worldwide</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>M. Gesicki</td>
                      <td>132.8</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>R. Gronkowski</td>
                      <td>127.3</td>
                      <td>joog</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>H. Hurst</td>
                      <td>122.6</td>
                      <td>Prestige Worldwide</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>J. Smith</td>
                      <td>121.2</td>
                      <td>Back Ditch Water</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>J. Graham</td>
                      <td>119.1</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>N. Fant</td>
                      <td>118.3</td>
                      <td>GingerBeard77</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="cols-2">
              <div className="top-score-pos">
                <h3>Defense / Special Teams</h3>
                <table className="styled top-scorers">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Player</th>
                      <th>Total Points</th>
                      <th>Manager</th>
                    </tr>
                  </thead>
          
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Rams</td>
                      <td>172</td>
                      <td>GingerBeard77</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Steelers</td>
                      <td>161</td>
                      <td>Ckbenz</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Colts</td>
                      <td>155</td>
                      <td>The Gandy-Golden Boys</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Ravens</td>
                      <td>147</td>
                      <td>frankakatank</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Football Team</td>
                      <td>144</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Buccaneers</td>
                      <td>127</td>
                      <td>Chodemissioner</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Saints</td>
                      <td>126</td>
                      <td>Piss Jugs</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Dolphins</td>
                      <td>123</td>
                      <td>jhoffman</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>Bills</td>
                      <td>111</td>
                      <td>Back Ditch Water</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>Cardinals</td>
                      <td>107</td>
                      <td>GingerBeard77</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>Packers</td>
                      <td>105</td>
                      <td>GingerBeard77</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>Giants</td>
                      <td>103</td>
                      <td>The Gandy-Golden Boys</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Tier 1 Player Management</h2>
            <div className="chart-desc">(how many tier 1 players each team had)</div>
            <canvas id="chartTier1"></canvas>
          </div>
        </div>

        <div className="row">
          <div className="inner">
            <h2>Weekly Reward Winners</h2>
            <table className="styled weekly-reward">
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Title</th>
                  <th>Challenge</th>
                  <th>Winner</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ready Set Go</td>
                  <td>Team with the highest score</td>
                  <td>Wet Ass Poopy - 165.76</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Hero to Zero</td>
                  <td>Team with the biggest drop in score from Week 1 to Week 2</td>
                  <td>Wet Ass Poopy - 69.88 drop in score</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Air It Out</td>
                  <td>Team with the QB with the longest pass</td>
                  <td>Chodemissioner - Wilson had a 62.9 yard TD pass to Metcalf</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Gotta Catch Em All</td>
                  <td>Team with the WR with the most receptions</td>
                  <td>The Gandy-Golden Boys - Amari Cooper (12)</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Runt of the Litter</td>
                  <td>Winning team with the lowest scoring starter</td>
                  <td>joog - San Francisco 49ers (-5 pts)</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Got There</td>
                  <td>Team that beats its opponent by the smallest margin of victory</td>
                  <td>jhoffman - 4.1 pts</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Halftime</td>
                  <td>Team with the most total points after 7 weeks</td>
                  <td>Chodemissioner - 1029.54 pts</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Blackjack</td>
                  <td>Team with a starter closest to 21 points without going over</td>
                  <td>Wet Ass Poopy - T. Kelce (20.9 pts)</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>The Purge</td>
                  <td>Subtract last week's score from this weeks, the team with the highest remaining score wins</td>
                  <td>joog - 48.14 pts</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Right On Target</td>
                  <td>Team closest to their projected score (over OR under)</td>
                  <td>Back Ditch Water - 6 pts off of projected</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>At Least You Tried</td>
                  <td>Highest scoring losing team</td>
                  <td>The Gandy-Golden Boys - 123.72 pts</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Magic Legs</td>
                  <td>Team with the RB with the most rushing yards</td>
                  <td>Wet Ass Poopy - Derrick Henry (178 yards)</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>End Zone</td>
                  <td>Team with the most offensive touchdowns scored</td>
                  <td>GingerBeard77 - 9 TDs</td>
                </tr>

                <tr>
                  <td>14</td>
                  <td>Consolation Prize</td>
                  <td>Team with the most points for on the season that didn't make the playoffs</td>
                  <td>Prestige Worldwide - 1619.16 pts</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id="trades" className="row">
          <div className="inner">
            <h2>Trades</h2>

            <h3>Total Trades - {trades.length}</h3>
            <canvas id="chartTrades"></canvas>

            <h3>Trade Details</h3>
            <div id="trades-details">
              {trades.map((data,id)=>{
                return <Trade key={id} data={data} teamNames={teamNames} />
              })}
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default Page;